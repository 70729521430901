body {
    background-color: rgb(54, 146, 207);
}

img {
    width: 100%;
    border-radius: 10px;
}

.content {
    max-width: 600px;
}

.logo {
max-height: 250px;
}

#results {
    border-style: solid;
    border-color:#fff;
    border-width: 3pt;
    border-radius: 10px;
}

#results p {
    color: #fff;
    font-size: 24pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.funFactsTitle {
    color: #fff;
    font-size: 24pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.funFacts {
    color: #fff;
    font-size: 18pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.hint {
    color: #fff;
    font-size: 18pt;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#avocado {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 20%;
}

#avocado:hover {
    transform: rotate(20deg);
}

@media (min-width: 576px) {
    #avocado {
        width: 5%;
    }
 }